//@flow

import * as React from "react";
import { Section, Row, Column } from '../../../utils/layout/grid';
import Currency from '../../formatting/currency';
import { css } from '@emotion/react';
import colors from '../../../utils/config/colors';
import Color from 'color';
import { Link } from "gatsby";

const pricingData = {
  slotDiscount: 0.9,
  slotSize: 25,
  roundTo: 50,
  basePricing: {
    'monthly': 3125,
    'quarterly': 2500,
    'annual': 2000
  },
  contractLength: {
    'monthly': 1,
    'quarterly': 3,
    'annual': 12
  }
};
type PriceInfo = {
  contract: string,
  size: number,
  monthlyRate: number,
  monthlyHours: number,
  hourlyRate: number,
  totalHours: number,
  initialPayment: number
}

function calculateRate(contract: string, size: number): PriceInfo {
  var monthlyRate = pricingData.basePricing[contract.toLowerCase()];
  if (size > 1) {
    monthlyRate+=((size-1)*(monthlyRate*pricingData.slotDiscount) );
  }

  let contractLength = pricingData.contractLength[contract.toLowerCase()];
  let monthlyHours = size*pricingData.slotSize;
  let hourlyRate = monthlyRate / monthlyHours;
  let initialPayment = monthlyRate * contractLength;
  let totalHours = monthlyHours * contractLength;
  return {contract, size, monthlyRate, monthlyHours, hourlyRate, totalHours, initialPayment};
}

let cellBackgroundColor = Color(colors.action).lighten(0.65).hex();


const PriceEntry = ({contract, size}: {contract:string, size:number }) => {
  let priceInfo = calculateRate(contract, size);
  const entryCss = css`
    :hover {
      border: solid 1px ${colors.action};
      background-color: ${cellBackgroundColor};
    }
    background-color: #FFFFFF;
    transition 0.5s background-color ease, 0.5s border-color ease;
    cursor: pointer;
    border: solid 1px rgba(0,0,0,0);
    padding: 1rem 1rem;
    width: 100%;
    height: 100%;
  `;
  return (
    <div css={entryCss}>
      <h2><Currency value={priceInfo.initialPayment} round/></h2>
      <p>{priceInfo.totalHours} hours</p>
      <p><Currency value={priceInfo.hourlyRate} round/>/hr</p>
    </div>
  );
};

const rowCss = css`
  border-bottom: solid 1px #eee;
  padding: 1rem 0 0 0;
  h2 {
    margin: 0;
    padding: 0;
  }
  `;

const PriceRow = ({contract}: {contract: string}) => {
  return (
    <Row css={rowCss} vcenter>
      <Column size={3} mobileSize={12}>
        <div className='text-right padded-x'>
          <small>{contract}</small>
        </div>
      </Column>
      {[1, 2, 3].map(count => (
        <Column key={count} size={3} mobileSize={4}>
          <PriceEntry contract={contract} size={count}/>
        </Column>
      ))}
    </Row>
  );
};

const Matrix = () => {
  return (
    <Section gutters >
      <Row css={rowCss}>
        <Column size={3} mobileSize={3}/>
        {[1,2,3].map(count => <Column key={count} size={3} mobileSize={3}><h4 css={css`padding-left:1rem;`}>{count}x</h4></Column>)}
      </Row>
      {['monthly', 'quarterly', 'annual'].map(contract => (
        <PriceRow
           key={contract}
           contract={contract}
         />
      ))}
    </Section>
  );
};

function MembershipPricing (): React.Node {
  return (
    <Section>
      <Row>
        <Column textCenter readable>
          <h2>Membership Pricing</h2>
          <p>Memberships are paid in advance, due before work is completed. Unused hours rollover with renewal. If you don't see a plan that fits what you need, <Link to="/contact">drop us a line</Link> and we'll see how we can help.</p>
        </Column>
      </Row>
      <Row vcenter>
        <Column>
          <Matrix />
        </Column>
      </Row>
    </Section>
  );
}

export default MembershipPricing;
